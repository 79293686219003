import React, { useState, useRef } from 'react'
import axios from 'axios'
import apiAxios from '../api/axios'
import './VideoUpload.css'
// import HashtagSelector from '../components/HashtagSelector'
// import HashtagItemsWithRemove from '../components/hashtag/HashtagItemsWithRemove'

const VideoUpload = () => {
  const fileInput = useRef(null)
  const abortController = useRef(null)

  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [uploadProgress, setUploadProgress] = useState(0)
  const [notification, setNotification] = useState('')
  const [cancelMessage, setCancelMessage] = useState('')
  const [uploadResponse, setUploadResponse] = useState(null)
  const [fileName, setFileName] = useState('Выберите файл')
  const [isUploading, setIsUploading] = useState(false)

  const [selectedHashtags, setSelectedHashtags] = useState([]) // Для хранения выбранных хэштегов
  const [hashtagStatus, setHashtagStatus] = useState('') // Для уведомлений о хэштегах

  const handleFileSelect = (event) => {
    const file = event.target.files[0]
    if (file && file.type.startsWith('video/')) {
      setFileName(file.name)
      setUploadResponse(null)
      setNotification('')
      setHashtagStatus('')
      setCancelMessage('')
    } else {
      setFileName('Выберите файл')
      showError('Пожалуйста, выберите видеофайл.')
      fileInput.current.value = ''
    }
  }

  const showError = (message) => {
    window.alert('Error: ' + message)
  }

  const uploadVideo = async () => {
    setUploadProgress(0)
    setNotification('')
    setCancelMessage('')
    setHashtagStatus('')

    if (title.length < 4) {
      showError('Название должно содержать не менее 4 символов.')
      return
    }

    const file = fileInput.current.files[0]

    if (!file) {
      showError('Видео не выбрано')
      return
    }

    if (file.size > 40 * 1024 * 1024) {
      showError('Пожалуйста, выберите видеофайл размером менее 40 МБ.')
      return
    }

    const formData = new FormData()
    formData.append('small_240', file)
    formData.append('title', title)
    formData.append('description', description)

    try {
      setIsUploading(true)
      abortController.current = new AbortController()
      const { signal } = abortController.current

      const response = await apiAxios.post(
        '/api/admin/v1/content/facts/upload',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            )
            setUploadProgress(percentCompleted)
          },
          signal
        }
      )

      if (response.data) {
        setUploadResponse(response.data)
        setNotification('Видео успешно загружено!')

        // Автоматически отправляем выбранные хэштеги
        if (selectedHashtags.length > 0) {
          sendSelectedHashtags(response.data.video[0].uuid, selectedHashtags)
        }
      } else {
        showError('Не удалось загрузить.' + response.data.status)
      }
    } catch (error) {
      if (axios.isCancel(error) || error.code === 'ERR_CANCELED') {
        setCancelMessage('Загрузка отменена.')
      } else {
        showError('Не удалось загрузить.' + error.message)
      }
    } finally {
      setIsUploading(false)
      setUploadProgress(0)
      setTitle('')
      setDescription('')
      setFileName('Выберите файл')
      fileInput.current.value = ''
    }
  }

  const cancelUpload = () => {
    if (abortController.current) {
      abortController.current.abort()
      setIsUploading(false)
    }
  }

  // Локальная обработка выбора хэштегов до загрузки видео
  // const handleSelectHashtagLocal = (selectedHashtag) => {
  //   if (selectedHashtags.find(tag => tag.uuid === selectedHashtag.uuid)) {
  //     alert('Этот хэштег уже выбран.')
  //     return
  //   }
  //   setSelectedHashtags([...selectedHashtags, selectedHashtag])
  // }

  // const handleRemoveHashtagLocal = (hashtagUuid) => {
  //   setSelectedHashtags(selectedHashtags.filter(tag => tag.uuid !== hashtagUuid))
  // }

  // Функция для отправки выбранных хэштегов после загрузки видео
  const sendSelectedHashtags = async (videoUuid, hashtags) => {
    setHashtagStatus('Отправка хэштегов...')
    try {
      const requests = hashtags.map(hashtag =>
        apiAxios.put(`/api/admin/v1/tag/video/${videoUuid}/${hashtag.uuid}`)
      )
      const responses = await Promise.all(requests)

      const allSuccessful = responses.every(response => response.status === 200)
      if (allSuccessful) {
        setHashtagStatus('Хэштеги добавлены!')
        setSelectedHashtags([]) // Очищаем выбранные хэштеги
      } else {
        setHashtagStatus('Некоторые хэштеги не удалось добавить.')
      }
    } catch (error) {
      setHashtagStatus('Ошибка при добавлении хэштегов: ' + error.message)
    }
  }

  return (
    <div className="video-upload-wrapper">
      <div className="video-upload-container">
        <div className="form-group">
          <label className="form-label" htmlFor="title">Заголовок:*</label>
          <input
            className="upload-input"
            maxLength={60}
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="description">Описание:</label>
          <textarea
            className="upload-input description"
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        {/* {selectedHashtags.length > 0 && (
            <div className="hashtags-section">
              <HashtagItemsWithRemove hashtags={selectedHashtags} onRemoveHashtag={handleRemoveHashtagLocal} />
            </div>
        )} */}
        {/* Раздел для выбора хэштегов */}
        {/* <div className="hashtags-section">
          <HashtagSelector onSelectHashtag={handleSelectHashtagLocal} />
        </div> */}

        <div className="file-upload-group">
          <label className="file-upload-button" htmlFor="file-input">{fileName}</label>
          <input
            className="file-input"
            type="file"
            id="file-input"
            ref={fileInput}
            accept="video/*"
            style={{ display: 'none' }}
            onChange={handleFileSelect}
          />
          <button
            className={`upload-button ${isUploading ? 'cancel-video-button' : ''}`}
            onClick={isUploading ? cancelUpload : uploadVideo}
          >
            {isUploading ? 'Отменить' : 'Загрузить'}
          </button>
        </div>
        {uploadProgress > 0 && (
          <progress className="upload-progress" value={uploadProgress} max="100" />
        )}
        {notification && <div className="upload-notification">{notification}</div>}
        {cancelMessage && <div className="cancel-notification">{cancelMessage}</div>}
        {hashtagStatus && <div className="hashtag-notification">{hashtagStatus}</div>}

        {uploadResponse && (
          <div>
            <p>Дата создания: {new Date(uploadResponse.created_at).toLocaleDateString('en-GB')}</p>
            <p>Автор: {uploadResponse.author.email} {uploadResponse.author.role}</p>
            <p>Заголовок: {uploadResponse.title}</p>
            {uploadResponse.description && <p>Описание: {uploadResponse.description}</p>}
            {uploadResponse.video.map((video, index) => (
              <div key={index}>
                <p>Имя файла: {video.file_name}</p>
                <p>Размер файла: {(video.size / 1048576).toFixed(1)} MB</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default VideoUpload
