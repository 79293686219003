import React, { useState, useEffect } from 'react'
import axios from '../api/axios'
import ConfirmationModal from '../components/ConfirmModal'
import styles from './HashtagList.module.css'
import Pagination from '@mui/material/Pagination'
import { useNavigate, useLocation } from 'react-router-dom'
import ModalHashtag from '../components/ModalHashtag'
import { useUser } from '../contexts/UserContext'

const HashtagList = () => {
  const { user } = useUser()
  const [hashtags, setHashtags] = useState([])
  const [currentHashtag, setCurrentHashtag] = useState('')
  const [currentHashtagObject, setCurrentHashtagObject] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [inputError, setInputError] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const [totalCount, setTotalCount] = useState(0)
  const limit = 10
  const [searchQuery, setSearchQuery] = useState('')
  const [lastSearchQuery, setLastSearchQuery] = useState('')

  const query = new URLSearchParams(location.search)
  const pageFromUrl = parseInt(query.get('page') || '1', 10)
  const zeroIndexedPage = pageFromUrl - 1

  const [debounceTimeout, setDebounceTimeout] = useState(null)
  const [inputValue, setInputValue] = useState('')

  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false)
  const [hashtagToDelete, setHashtagToDelete] = useState(null)
  const tagMaxLength = 35
  useEffect(() => {
    fetchHashtags()
  }, [zeroIndexedPage, searchQuery])

  const fetchHashtags = async () => {
    try {
      const offset = zeroIndexedPage * limit
      let apiUrl = `/api/admin/v1/tags?offset=${offset}&limit=${limit}`
      if (searchQuery) {
        apiUrl += `&query=${encodeURIComponent(searchQuery)}`
      }
      const response = await axios.get(apiUrl)
      setHashtags(response.data.tags)
      setTotalCount(response.data.count)
      setLastSearchQuery(searchQuery)
    } catch (error) {
      alert('Error fetching hashtags: ', error)
    }
  }

  const validateHashtag = (hashtag) => {
    // return /^[0-9а-яёА-Я-_]{2,35}$/.test(hashtag)
    return /^[0-9a-zA-Zа-яёА-Я-_ӨөҤҥҺһүҮҔҕ]{2,35}$/.test(hashtag)
  }

  const handleSave = () => {
    if (!validateHashtag(currentHashtag)) {
      setInputError(true)
      return
    }

    if (editMode) {
      // Update hashtag
      axios.put('api/admin/v1/tags', { uuid: currentHashtagObject.uuid, name: currentHashtag })
        .then(() => {
          setHashtags(hashtags.map(h => h.uuid === currentHashtagObject.uuid ? { ...h, name: currentHashtag } : h))
        })
        .catch(error => {
          if (error.response && error.response.status === 400) {
            setInputError(true)
            alert(' Ошибка при создании тега, ' + '#' + currentHashtag + '  ' + error.response?.data?.message)
          } else {
            alert(error)
          }
        })
    } else {
      // Add new hashtag
      axios.post('/api/admin/v1/tags', { name: currentHashtag })
        .then(() => {
          if (pageFromUrl !== 1) { navigate('?page=1') }
          fetchHashtags()
        })
        .catch(error => {
          if (error.response && error.response.status === 400) {
            setInputError(true)
            // console.log(error.response.status)
            // console.log(error.response.data.message)
            alert(' Ошибка при создании тега, ' + '#' + currentHashtag + ' ' + error.response?.data?.message)
          } else {
            alert(' Ошибка ' + error.response?.data?.message)
          }
        })
    }
    setIsModalOpen(false)
    setCurrentHashtag('')
    setInputError(false)
    setEditMode(false)
    setCurrentHashtagObject(null)
  }

  const handleEdit = (hashtag) => {
    setCurrentHashtag(hashtag.name)
    setCurrentHashtagObject(hashtag)
    setEditMode(true)
    setIsModalOpen(true)
    setInputError(false)
  }

  const openDeleteConfirm = (hashtag) => {
    setHashtagToDelete(hashtag)
    setIsDeleteConfirmOpen(true)
  }

  const handleDeleteConfirm = async () => {
    try {
      const response = await axios.delete(`api/admin/v1/tags/${hashtagToDelete.uuid}`)
      if (response.status === 200) {
        setHashtags(hashtags.filter(h => h.uuid !== hashtagToDelete.uuid))
        fetchHashtags()
        setIsDeleteConfirmOpen(false)
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        alert('Конфликт при удалении: этот тег используется, сначала снимите тег с видео. ' + error.message)
      } else {
        alert('Ошибка при удалении. ' + (error.response?.data?.message || error.message))
        // console.log(error)
      }
    }
  }

  const handleSearchChange = (event) => {
    const query = event.target.value
    setInputValue(query)
    if (debounceTimeout) {
      clearTimeout(debounceTimeout)
    }
    const newTimeout = setTimeout(() => {
      setSearchQuery(query)
      if (event.target.value !== lastSearchQuery && pageFromUrl !== 1) {
        navigate('?page=1')
      }
    }, 1000)
    setDebounceTimeout(newTimeout)
  }

  useEffect(() => {
    return () => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout)
      }
    }
  }, [debounceTimeout])

  const handleChangePage = (event, value) => {
    navigate(`?page=${value}`)
  }

  const totalPages = Math.ceil(totalCount / limit)

  return (
        <div className={styles.hashtagListContainer}>
          <div className='container'>
            <div className={styles.paginationContainer}>
                <Pagination
                    count={totalPages}
                    page={pageFromUrl}
                    onChange={handleChangePage}
                    showFirstButton
                    showLastButton
                />
            </div>
            <div className={styles.topControls}>
                <div className={styles.searchContainer}>
                    <span className={styles.boldHash}>#</span>
                    <input
                        type="text"
                        maxLength={tagMaxLength}
                        placeholder="Поиск..."
                        value={inputValue}
                        onChange={handleSearchChange}
                        className={styles.searchInput}
                    />
                </div>
                {user?.role === 'куратор' && (<button onClick={() => setIsModalOpen(true)} className={`${styles.hashtagButton} ${styles.addButton}`}>Создать хештег</button>)}
            </div>
            <ul className={styles.hashtagList}>
                {hashtags.map((hashtag) => (
                    <li key={hashtag.uuid} className={styles.hashtagItem}>
                        <span className={styles.hashtagName}>#{hashtag.name}</span>
                        {user?.role === 'куратор' && (<div className={styles.buttonGroup}>
                            <button className={styles.editButton} onClick={() => handleEdit(hashtag)}>Изменить</button>
                            <button className={styles.deleteButton} onClick={() => openDeleteConfirm(hashtag)}>Удалить</button>
                        </div>)}
                    </li>
                ))}
            </ul>
            <ConfirmationModal isOpen={isDeleteConfirmOpen} onClose={() => setIsDeleteConfirmOpen(false)} onConfirm={handleDeleteConfirm}>
                <p>Удалить этот хештег #{hashtagToDelete ? hashtagToDelete.name : ''} ?</p>
            </ConfirmationModal>
            <ModalHashtag isOpen={isModalOpen} onClose={() => { setEditMode(false); setCurrentHashtagObject(''); setIsModalOpen(false); setInputError(false); setCurrentHashtag('') }}>
                <div className={styles.modal}>
                <span>#</span>
                <input
                    minLength={2}
                    maxLength={tagMaxLength}
                    type="text"
                    value={currentHashtag}
                    onChange={(e) => { setCurrentHashtag(e.target.value); setInputError(false) }}
                    className={`${styles.inputField} ${inputError ? styles.inputError : ''}`}
                />
                {inputError && currentHashtag.length > 2 && (<p className={styles.errorMessage}>Допустимы русские, латинские, якутские буквы, цифры, дефисы и подчеркивания.<br/></p>)}
                {inputError && currentHashtag.length < 2 && (<p className={styles.errorMessage}>Длина хештега должна быть от 2 до {tagMaxLength} символов</p>)}
                </div>
                <button onClick={handleSave} className={styles.saveButton}>Сохранить</button>
            </ModalHashtag>
            </div>
        </div>
  )
}

export default HashtagList
