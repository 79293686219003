import React from 'react'
import { NavLink } from 'react-router-dom'
import styles from './AdminNavbar.module.css'
import { useUser } from '../contexts/UserContext'

const AdminNavbar = () => {
  const { user } = useUser()
  return (
    <div className={styles.adminNavbar}>
      <div className='container'>
        <div className={styles.linkContainer}>
            <NavLink
              to="/admin/entertaining-content"
              className={({ isActive }) =>
                isActive ? styles.activeNavLink : styles.navLink
              }
            >
              Развлекательный раздел
            </NavLink>
            {user?.role === 'куратор' && (
              <NavLink
                to="/admin/educational-content"
                className={({ isActive }) =>
                  isActive ? styles.activeNavLink : styles.navLink
                }
              >
                Образовательный раздел
              </NavLink>
            )}
            {user?.role === 'куратор' && (
              <NavLink
                to="/admin/coupons"
                className={({ isActive }) =>
                  isActive ? styles.activeNavLink : styles.navLink
                }
              >
                Купоны
              </NavLink>
            )}
            {user?.role === 'куратор' && (
              <NavLink
                to="/admin/users"
                className={({ isActive }) =>
                  isActive ? styles.activeNavLink : styles.navLink
                }
              >
                Пользователи
              </NavLink>
            )}
          </div>
        </div>
      </div>
  )
}

export default AdminNavbar
