/*eslint-disable*/
import React, {useEffect, useState} from "react";
import axios from "../api/axios";
import Modal from "./Modal";
import styles from "./VideoList.module.css";
import Pagination from "@mui/material/Pagination";
import {useLocation, useNavigate} from "react-router-dom";
import VideoEdit from "../components/videoEdit";
import ModalHashtag from "../components/ModalHashtag";
import * as PropTypes from "prop-types";
import {GroupActions} from "../components/entertain/list/GroupActions";
import videoPoster from '../assets/video_poster.png'
import CategorySelector from '../components/categories/CategorySelector'
import HashtagItemsWithRemove from '../components/hashtag/HashtagItemsWithRemove'
import HashtagSelector from '../components/HashtagSelector'
import { useUser } from '../contexts/UserContext'

GroupActions.propTypes = {
    video: PropTypes.any,
    onClick: PropTypes.func,
    onClick1: PropTypes.func
};

const VideoList = () => {
    const { user } = useUser();
    const [videos, setVideos] = useState([]);
    const [error, setError] = useState(null);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalHashtagOpen, setIsModalHashtagOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [totalCount, setTotalCount] = useState(0);
    const limit = 10;
    const [searchQuery, setSearchQuery] = useState("");
    const [lastSearchQuery, setLastSearchQuery] = useState("");

    const query = new URLSearchParams(location.search);
    const pageFromUrl = parseInt(query.get("page") || "1", 10);
    const zeroIndexedPage = pageFromUrl - 1;

    const [debounceTimeout, setDebounceTimeout] = useState(null)
    const [inputValue, setInputValue] = useState('')

    const [searchType, setSearchType] = useState('all');
    const [tagsQuery, setTagsQuery] = useState([]);  // Для хранения тегов
    const [categoriesQuery, setCategoriesQuery] = useState([]);  // Для хранения категорий
    

    const fetchHashtags = async (videoUuid) => {
        try {
          const hashtagResponse = await axios.get(`/api/admin/v1/tag/video/${videoUuid}`);
          return hashtagResponse.data.tags || []; // возвращаем хештеги для видео
        } catch (err) {
        //   alert('Error fetching hashtags: ', err);
          console.log('Error fetching hashtags: ', err);
          return []; // В случае ошибки возвращаем пустой массив
        }
      };

      const fetchObjectCategories = async (videoUuid) => {
        try {
          const categoriesResponse = await axios.get(`/api/admin/v1/cats/video/${videoUuid}`);
          return categoriesResponse.data.cats || []; // возвращаем категории для видео
        } catch (err) {
        //   alert('Error fetching hashtags: ', err);
          console.log('Error fetching categories: ', err);
          return [];
        }
      };

    const fetchVideos = async () => {
        try {
          const offset = zeroIndexedPage * limit;
          let apiUrl = `api/admin/v1/content/facts?offset=${offset}&limit=${limit}`;
            if (searchQuery && searchType === 'all') {

            apiUrl += `&query=${encodeURIComponent(searchQuery)}`;
          }
          if (tagsQuery.length > 0 && searchType === 'hashtags') {
            apiUrl = `api/admin/v1/videos?offset=${offset}&limit=${limit}&tags=${encodeURIComponent(tagsQuery.join(','))}`;
          }
        
        if (categoriesQuery.length > 0 && searchType === 'categories') {
            apiUrl = `api/admin/v1/videos?offset=${offset}&limit=${limit}&cats=${encodeURIComponent(categoriesQuery.join(','))}`;
          }
          const response = await axios.get(apiUrl);
          const videosWithHashtagsCats = await Promise.all(
            response.data.videos.map(async (video) => {
              const hashtags = await fetchHashtags(video.uuid); // Получаем хештеги для каждого видео
              const categories = await fetchObjectCategories(video.uuid); // Получаем категории для каждого видео
              return { ...video, hashtags, categories }; // Добавляем хештеги и категории к видео
            })
          );
          setVideos(videosWithHashtagsCats);
        //   console.log(videos)
          setTotalCount(response.data.count);
          setLastSearchQuery(searchQuery);
        } catch (error) {
          alert("Error fetching videos: ", error);
          setError("Error fetching videos");
        }
      };

    useEffect(() => {
        fetchVideos();
}, [zeroIndexedPage, searchQuery, categoriesQuery, tagsQuery]);

    const handleSearchCategory = (category) => {
        setCategoriesQuery((prevCategories) => {
            if (prevCategories.includes(category.name)) {
                return prevCategories;
            } else {
                const newCategories = [...prevCategories, category.name];
                navigate("?page=1");
                return newCategories;
            }
        });
    };

    const handleSearchTags = (tag) => {
        setTagsQuery((prevTags) => {
            if (prevTags.includes(tag.name)) {
                return prevTags;
            } else {
                const newTags = [...prevTags, tag.name]; // Добавляем новую категорию
                navigate("?page=1");
                return newTags;
            }
        });
    };

    const handleRemoveSearchTag = (tagName) => { 
        setTagsQuery((prevTags) =>
            prevTags.filter((tag) => tag !== tagName)
        );
    }
    const handleRemoveCategoryQuery = (categoryName) => {
        setCategoriesQuery((prevCategories) =>
          prevCategories.filter((cat) => cat !== categoryName) // Удаляем категорию по имени
        );
    };

    const handleEditClick = (clickEvent, video) => {
        clickEvent.stopPropagation();

        setSelectedVideo(video);
        setIsModalHashtagOpen(true);
    };

    const deleteVideo = async (clickEvent, videoUuid) => {
        clickEvent.stopPropagation();
        if (confirm("Удалить выбранный элемент?\nНажмите Отмена если нажали случайно") == false) {
            return
        }
        try {
            const response = await axios.delete(
                `api/admin/v1/video/${videoUuid}`
            );
            if (response.status === 200) {
                setVideos(videos.filter((video) => video.uuid !== videoUuid));
                // fetchVideos();
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    setError("Unauthorized: Invalid JWT");
                } else if (error.response.status === 403) {
                    setError("Forbidden: Access Denied");
                } else {
                    setError("Error deleting video");
                }
            } else {
                setError("Произошла ошибка при удалении видео " + error);
            }
            console.error("Error deleting video: ", error);
            setTimeout(() => setError(null), 3000);
        }
    };

    const handleSearchChange = (event) => {
        const query = event.target.value
        setInputValue(query)
        if (debounceTimeout) {
          clearTimeout(debounceTimeout)
        }
        const newTimeout = setTimeout(() => {
            setSearchQuery(query)
            if (event.target.value !== lastSearchQuery && pageFromUrl !== 1) {
                navigate("?page=1");
            }
        }, 1000)
        setDebounceTimeout(newTimeout)
    };

    useEffect(() => {
        return () => {
            if (debounceTimeout) {
                clearTimeout(debounceTimeout);
            }
        };
    }, [debounceTimeout]);
    
    // const handleQualityClick = async (videoId, quality) => {
    //     try {
    //         const response = await axios.get(
    //             `api/admin/v1/content/facts/${videoId}/${quality}`
    //         );
    //         setSelectedVideo(response.data);
    //         setIsModalOpen(true);
    //     } catch (error) {
    //         console.error("Error fetching video: ", error);
    //     }
    // };

    const handleOpenVideo = async (videoUuid) => {
        try {
            const response = await axios.get(
                `api/public/v1/video/${videoUuid}`
            );
            setSelectedVideo(response.data);
            setIsModalOpen(true);
        } catch (error) {
            console.error("Error fetching video: ", error);
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    const closeModalHashtag = () => {
        setIsModalHashtagOpen(false);
    };

    const handleChangePage = (event, value) => {
        navigate(`?page=${value}`);
    };

    const totalPages = Math.ceil(totalCount / limit);

    const updateVideoHashtags = (videoUuid, newHashtags) => {
        setVideos((prevVideos) =>
          prevVideos.map((video) => {
            if (video.uuid === videoUuid) {
              return { ...video, hashtags: newHashtags }
            }
            return video;
          })
        )
      }
    
      const updateVideoCategories = (videoUuid, newCategories) => {
        setVideos((prevVideos) =>
          prevVideos.map((video) => {
            if (video.uuid === videoUuid) {
              return { ...video, categories: newCategories }
            }
            return video;
          })
        )
      }

    return (
        <div className={styles.videoListContainer}>
            <div className='container'>
                {error ? (
                    <p>{error}</p>
                ) : (
                    <div>
                        <div className={styles.paginationContainer}>
                            <Pagination
                                count={totalPages}
                                page={zeroIndexedPage + 1}
                                onChange={handleChangePage}
                                showFirstButton
                                showLastButton
                            />
                        </div>
                        
                        <div className={styles.searchTabs}>
                            <button
                            className={`${styles.tab} ${searchType === 'all' ? styles.active : ''}`}
                            onClick={() => { navigate("?page=1"); setCategoriesQuery([]); setTagsQuery([]); setSearchType('all') }}
                            >
                            Все
                            </button>
                            {user?.role === 'куратор' && (
                                <>
                                    <button
                                        className={`${styles.tab} ${searchType === 'hashtags' ? styles.active : ''}`}
                                        onClick={() => { navigate("?page=1"); setCategoriesQuery([]); setSearchType('hashtags')}}
                                        >
                                        Хештеги
                                        </button>
                                        <button
                                        className={`${styles.tab} ${searchType === 'categories' ? styles.active : ''}`}
                                        onClick={() => { navigate("?page=1"); setTagsQuery([]); setSearchType('categories') }}
                                        >
                                        Категории
                                    </button>
                                </>
                            )}
                        </div>

                        {searchType === 'all' && 
                        <div className={styles.searchContainer}>
                            <div className={styles.searchIcon}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    height="24"
                                >
                                    <path
                                        fill="black"
                                        d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16a6.468 6.468 0 0 0 4.23-1.392l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
                                    ></path>
                                </svg>
                            </div>
                            <input
                                type="text"
                                maxLength={49}
                                placeholder="Поиск..."
                                value={inputValue}
                                onChange={handleSearchChange}
                                className={styles.searchInput}
                            />
                        </div>
                        }

                        {searchType === 'categories' &&
                            <div className={styles.searchTypeContainer}>
                                <CategorySelector
                                    onSelectCategory={handleSearchCategory}
                                />
                                <HashtagItemsWithRemove
                                    hashtags={categoriesQuery.map((name) => ({ uuid: name, name }))} // Преобразуем строки в объекты
                                    onRemoveHashtag={(uuid) => { navigate("?page=1"); handleRemoveCategoryQuery(uuid)} }// Передаем функцию удаления
                                    hideHash={true}
                                />
                            </div>
                        }
                        
                        {searchType === 'hashtags' && 
                            <div className={styles.searchTypeContainer}>
                                <HashtagSelector onSelectHashtag={handleSearchTags} />
                                <HashtagItemsWithRemove
                                    hashtags={tagsQuery.map((name) => ({ uuid: name, name }))}
                                    onRemoveHashtag={ (uuid) => { navigate("?page=1"); handleRemoveSearchTag(uuid)} }
                                />
                            </div>
                        }
                            
                        <ul className={styles.videoList}>
                            {videos.length > 0 ? videos.map((video) => (
                                <li
                                key={video.uuid}
                                className={styles.videoItem}
                                // onClick={() => handleQualityClick(video.id, "small_240")}
                                onClick={() => handleOpenVideo(video.uuid)}
                                >
                                    <img
                                        src={video.poster_url}
                                        onError={ (e) => { e.target.onError = null; e.target.src = videoPoster} }
                                        alt={video.title}
                                        className={styles.videoThumbnail}
                                    />
                                    <div className={styles.videoListInfoWrapper}>
                                        <div className={styles.videoInfo}>
                                            <h3 className={styles.videoTitle}>{video.title}</h3>
                                            <p className={styles.videoDetails}>
                                            {new Date(video.created_at).toLocaleDateString("en-GB")}
                                            </p>
                                            <p className={styles.videoDetails}>{video.author.email}</p>
                                            {video.description && (<p className={styles.videoDetails}>Описание: {video.description} </p>)}
                                            <p> Категории: {' '}
                                                {video.categories && video.categories.length > 0 ? (
                                                    video.categories.map((cats, index) => (
                                                    <span key={index} className={styles.category}>{cats.name}</span>))
                                                ) 
                                                    : ''
                                                }
                                            </p>
                                            <p> Теги:{' '}
                                                {video.hashtags && video.hashtags.length > 0 ? (
                                                    video.hashtags.map((hashtag, index) => (
                                                    <span key={index} className={styles.hashtag}>#{hashtag.tag_name} </span>))
                                                ) 
                                                    : ''
                                                }
                                            </p>
                                        </div>
                                        <div className={styles.buttonsWrapper}>
                                            <GroupActions
                                                video={video}
                                                onClick={(clickEvent) => handleEditClick(clickEvent, video)}
                                                onClick1={(clickEvent) => deleteVideo(clickEvent, video.uuid)}
                                            />
                                        </div>
                                        
                                    </div>
                                </li>
                            )) : <li> </li>
                            }
                            </ul>

                        <Modal
                            isOpen={isModalOpen}
                            onClose={closeModal}
                            videoUrl={selectedVideo ? selectedVideo.url : ""}
                            title={selectedVideo ? selectedVideo.title : ""}
                        />
                        <ModalHashtag isOpen={isModalHashtagOpen} onClose={closeModalHashtag}>
                            {selectedVideo && (
                                <VideoEdit videoId = {selectedVideo.id} videoUuid={selectedVideo.uuid} currentTitle={selectedVideo.title}
                                           currentDescription={selectedVideo.description} fetchVideos={fetchVideos} currentHashtags = {selectedVideo.hashtags} setCurrentHashtags={(updatedHashtags) =>
                                            setSelectedVideo({ ...selectedVideo, hashtags: updatedHashtags })
                                          } updateParentVideoHashtags={updateVideoHashtags} currentCategories = {selectedVideo.categories} setCurrentCategories={(updatedCategories) =>
                                            setSelectedVideo({ ...selectedVideo, categories: updatedCategories })
                                          } updateParentVideoCategories={updateVideoCategories}
                                           onClose={closeModalHashtag}/>
                            )}
                        </ModalHashtag>
                        <div className={styles.paginationContainer}>
                            <Pagination
                                count={totalPages}
                                page={zeroIndexedPage + 1}
                                onChange={handleChangePage}
                                showFirstButton
                                showLastButton
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default VideoList;