/*eslint-disable */
import React, { useState, useEffect, useRef } from 'react'
import axios from '../../api/axios'
import ConfirmationModal from '../ConfirmModal'
import styles from '../../pages/HashtagList.module.css'
import style from './CategoriesList.module.css'
import Pagination from '@mui/material/Pagination'
import { useNavigate, useLocation } from 'react-router-dom'
import ModalHashtag from '../ModalHashtag'
import ImgUploader from '../ImgUploader'
import partnerLogo from '../../assets/partner_logo.svg'

const CategoryList = () => {
  const [categories, setCategories] = useState([])
  const [currentCategory, setCurrentCategory] = useState('')
  const [currentCategoryObject, setCurrentCategoryObject] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [inputError, setInputError] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const [totalCount, setTotalCount] = useState(0)
  const limit = 10
  const [searchQuery, setSearchQuery] = useState('')
  const [lastSearchQuery, setLastSearchQuery] = useState('')

  const query = new URLSearchParams(location.search)
  const pageFromUrl = parseInt(query.get('page') || '1', 10)
  const zeroIndexedPage = pageFromUrl - 1

  const [debounceTimeout, setDebounceTimeout] = useState(null)
  const [inputValue, setInputValue] = useState('')

  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false)
  const [categoryToDelete, setCategoryToDelete] = useState(null)
  const [selectedFile, setSelectedFile] = useState(null)
  const ImgUploaderRef = useRef(null)

  useEffect(() => {
    fetchCategories()
  }, [zeroIndexedPage, searchQuery])

  const fetchCategories = async () => {
    try {
      const offset = zeroIndexedPage * limit
      let apiUrl = `/api/admin/v1/cats?offset=${offset}&limit=${limit}`
      if (searchQuery) {
        apiUrl += `&query=${encodeURIComponent(searchQuery)}`
      }
      const response = await axios.get(apiUrl)
      setCategories(response.data.category)
      setTotalCount(response.data.count)
      setLastSearchQuery(searchQuery)
    } catch (error) {
      alert('Ошибка при получении данных категорий', error)
    }
  }

  const validateHashtag = (hashtag) => {
    return /^[0-9a-zA-Zа-яёА-Я-_ӨөҤҥҺһүҮ Ҕҕ]{2,60}$/.test(hashtag)

  }

  const handleSave = async () => {
    if (!validateHashtag(currentCategory)) {
      setInputError(true)
      return
    }

    try {
      if (editMode) {
        // Обновляем категорию
        await axios.put('api/admin/v1/cats', { uuid: currentCategoryObject.uuid, name: currentCategory })

        // Если выбран файл, загружаем его
        if (selectedFile) {
          await ImgUploaderRef.current.uploadImage(currentCategoryObject.uuid)
        }

        fetchCategories()
      } else {
        // Создаем категорию
        const response = await axios.post('/api/admin/v1/cats', { name: currentCategory })

        // Если выбран файл, загружаем его
        if (selectedFile) {
          await ImgUploaderRef.current.uploadImage(response.data.uuid)
        // fetchCategories()
        }

        // Обновляем категории
        if (pageFromUrl !== 1) {
          navigate('?page=1')
        }
        fetchCategories()
      }

      setIsModalOpen(false)
      setCurrentCategory('')
      setInputError(false)
      setEditMode(false)
      setCurrentCategoryObject(null)
    } catch (error) {
      if (error.response && error.response.status === 418) {
        alert('Ошибка при создании категории, ' + currentCategory + ' уже существует')
      } else {
        alert(error)
      }
    }
  }

  const handleEdit = (hashtag) => {
    setCurrentCategory(hashtag.name)
    setCurrentCategoryObject(hashtag)
    setEditMode(true)
    setIsModalOpen(true)
    setInputError(false)
  }

  const openDeleteConfirm = (hashtag) => {
    setCategoryToDelete(hashtag)
    setIsDeleteConfirmOpen(true)
  }

  const handleDeleteConfirm = async () => {
    try {
      const response = await axios.delete(`api/admin/v1/cats/${categoryToDelete.uuid}`)
      if (response.status === 200) {
        setCategories(categories.filter(h => h.uuid !== categoryToDelete.uuid))
        fetchCategories()
        setIsDeleteConfirmOpen(false)
      }
    } catch (error) {
      // alert(error.response)
      if (error.response && error.response.status === 409) {
        alert('Конфликт при удалении: категория используется, сначала снимите категорию с видео. ' + error.message)
      } else {
        alert(error.message)
      }
    }
  }

  const handleSearchChange = (event) => {
    const query = event.target.value
    setInputValue(query)
    if (debounceTimeout) {
      clearTimeout(debounceTimeout)
    }
    const newTimeout = setTimeout(() => {
      setSearchQuery(query)
      if (event.target.value !== lastSearchQuery && pageFromUrl !== 1) {
        navigate('?page=1')
      }
    }, 1000)
    setDebounceTimeout(newTimeout)
  }

  useEffect(() => {
    return () => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout)
      }
    }
  }, [debounceTimeout])

  const handleChangePage = (event, value) => {
    navigate(`?page=${value}`)
  }

  const totalPages = Math.ceil(totalCount / limit)

  return (
        <div className={styles.hashtagListContainer}>
          <div className='container'>
            <div className={styles.paginationContainer}>
                <Pagination
                    count={totalPages}
                    page={pageFromUrl}
                    onChange={handleChangePage}
                    showFirstButton
                    showLastButton
                />
            </div>
            <div className={styles.topControls}>
                <div className={styles.searchContainer}>
                    <input
                        type="text"
                        maxLength={60}
                        placeholder="Поиск..."
                        value={inputValue}
                        onChange={handleSearchChange}
                        className={styles.searchInput}
                    />
                </div>
                <button onClick={() => setIsModalOpen(true)} className={`${styles.hashtagButton} ${styles.addButton}`}>Создать категорию</button>
            </div>
            <ul className={styles.hashtagList}>
                {categories.map((category) => (
                    <li key={category.uuid} className={styles.hashtagItem}>
                        <div className={style.caregoryContent}>
                          <img className={style.categoryImg} src={category.url || partnerLogo} alt="img"/>
                          <span className={style.categoryName}>{category.name}</span>
                        </div>
                        <div className={styles.buttonGroup}>
                            <button className={styles.editButton} onClick={() => handleEdit(category)}>Изменить</button>
                            <button className={styles.deleteButton} onClick={() => openDeleteConfirm(category)}>Удалить</button>
                        </div>
                    </li>
                ))}
            </ul>
            <ConfirmationModal isOpen={isDeleteConfirmOpen} onClose={() => setIsDeleteConfirmOpen(false)} onConfirm={handleDeleteConfirm}>
                <p>Удалить категорию {categoryToDelete ? categoryToDelete.name : ''} ?</p>
            </ConfirmationModal>
            <ModalHashtag isOpen={isModalOpen} onClose={() => { setIsModalOpen(false); setInputError(false); setCurrentCategory(''); setEditMode(false); setCurrentCategoryObject('') }}>
                <ImgUploader
                  ref={ImgUploaderRef}
                  onFileSelect={(file) => setSelectedFile(file)}
                  entityUuid={currentCategoryObject ? currentCategoryObject.uuid : ''}
                  imageUrl={currentCategoryObject ? currentCategoryObject.url : ''}
                  imageUuid={currentCategoryObject ? currentCategoryObject.image_uuid : ''}
                  // imageUuid={currentCategoryObject ? currentCategoryObject.url : ''}
                  setImageUuid={(image_uuid) => {}}
                  onImageUpdate={(url, image_uuid) => {
                    setCurrentCategoryObject((prevState) => ({ ...prevState, url, image_uuid }))
                  }}
                  fetchEntities={fetchCategories}
                  uploadApiUrl={'/api/admin/v1/cats/upload/'}
                  updateApiUrl={'/api/admin/v1/cats/update/'}
                  deleteApiUrl={'/api/admin/v1/cats/delete/'}
                  defaultImage={partnerLogo}
                />
                <div className={styles.modal}>
                <input
                    minLength={2}
                    maxLength={60}
                    type="text"
                    value={currentCategory}
                    onChange={(e) => { setCurrentCategory(e.target.value); setInputError(false) }}
                    className={`${styles.inputField} ${inputError ? styles.inputError : ''}`}
                />
                {inputError && (<p className={styles.errorMessage}>  Длина категории должна быть от 2 до 60 символов, допустимы русские, латинские, якутские буквы, цифры, пробел, дефисы и подчеркивания. <br/></p>)}
                </div>
                <button onClick={handleSave} className={styles.saveButton}>Сохранить</button>
            </ModalHashtag>
            </div>
        </div>
  )
}

export default CategoryList
