/*eslint-disable */
import React, { useState, useEffect, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from '../api/axios'
import { useUser } from '../contexts/UserContext'
import styles from './Login.module.css'
import eyeIcon from '../assets/eye_icon.svg'
import closedEyeIcon from '../assets/closed_eye_icon.svg'
import { homePagePath } from '../utils/routePaths'

const Login = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const { setUser, setAvatar } = useUser()
  const [passwordVisible, setPasswordVisible] = useState(false)
  const vkOneTapInitialized = useRef(false)
  const [pkceParams, setPkceParams] = useState(null)
  const pageUrl = `https://admin.${process.env.REACT_APP_L_SITE_URL.replace('https://', '')}/admin/login`
  // console.log(pageUrl)

  useEffect(() => {
    const jwt = localStorage.getItem('jwt')
    if (jwt) {
      navigate( homePagePath, { replace: true })
    }

    if (window.VKIDSDK) {
      generatePKCEParams()

    }
  }, [])

  // Генерация PKCE параметров
  const generatePKCEParams = async () => {
    const codeVerifier = generateRandomString(98)
    const codeChallenge = base64UrlEncode(await sha256(codeVerifier))
    const state = generateRandomString(16) // Строка состояния
    setPkceParams({ codeVerifier, codeChallenge, state })
  }

  const generateRandomString = (length) => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_'
    let result = ''
    for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length))
    }
    return result
  }

  const base64UrlEncode = (str) => {
    return btoa(String.fromCharCode.apply(null, new Uint8Array(str)))
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '')
  }

  const sha256 = (s) => {
    const buffer = new TextEncoder().encode(s)
    return window.crypto.subtle.digest('SHA-256', buffer)
  }

  useEffect(() => {
    if (pkceParams && !vkOneTapInitialized.current) {
      // console.log(pkceParams)
      initVKOneTap(pkceParams)
    }
  }, [pkceParams]) // После генерации PKCE

  const initVKOneTap = (pkceParams) => {
    if (vkOneTapInitialized.current) return
    vkOneTapInitialized.current = true

    const VKID = window.VKIDSDK


    VKID.Config.init ({
      app: 52938232,
      // redirectUrl: 'http://localhost',
      redirectUrl: pageUrl,
      responseMode: VKID.ConfigResponseMode.Callback,
      scope: 'vkid.personal_info email',
      codeChallenge: pkceParams.codeChallenge,
      state: pkceParams.state
    })

    const oneTapContainer = document.getElementById('VkIdSdkOneTap')
    if (oneTapContainer && oneTapContainer.children.length === 0) {
      const oneTap = new VKID.OneTap()
      oneTap.render({
        container: oneTapContainer,
        showAlternativeLogin: true
      })
      .on(VKID.WidgetEvents.ERROR, vkidOnError)
      .on(VKID.OneTapInternalEvents.LOGIN_SUCCESS, (payload) => {
        exchangeCodeForTokens(payload.code, payload.device_id)
      })
    }
  }

  const exchangeCodeForTokens = async (code, deviceId) => {
    try {
      const response = await axios.post('/api/vkauth', {
        code,
        state: pkceParams.state,
        code_verifier: pkceParams.codeVerifier,
        device_id: deviceId
      })
      const { jwt, message } = response.data
      if (jwt) {
        localStorage.setItem('jwt', jwt)
        const userDetailsResponse = await axios.get('/api/admin/v1/user')
        setUser(userDetailsResponse.data)
        navigate(homePagePath)

        const avatarResponse = await axios.get('/api/admin/v1/avatar')
        setAvatar(avatarResponse.data)
      } else {
        setError(message)
      }
    } catch (error) {
      console.log('Ошибка при обмене кода:', error)
      setError('Произошла ошибка при обмене кода')
    }
  }

  const vkidOnError = (error) => {
    console.log('Ошибка VK ID:', JSON.stringify(error))
  }

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible)
  }

  const MailIcon = () => (
    <svg
      className={styles.inputIcon}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4-8 5-8-5V6l8 5 8-5v2z" />
    </svg>
  )

  const LockIcon = () => (
    <svg
      className={styles.inputIcon}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <path d="M12 17c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm6-7h-1V7c0-2.76-2.24-5-5-5S7 4.24 7 7v3H6c-1.1 0-2 .9-2 2v9c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-9c0-1.1-.9-2-2-2zm-6 0H9V7c0-1.65 1.35-3 3-3s3 1.35 3 3v3z" />
    </svg>
  )

  const handleLogin = async (e) => {
    e.preventDefault()
    try {
      const response = await axios.post('/api/login', {
        email,
        password
      })
      const { jwt, message } = response.data
      if (jwt) {
        localStorage.setItem('jwt', jwt)

        try {
          const userDetailsResponse = await axios.get('/api/admin/v1/user')
          setUser(userDetailsResponse.data)
          navigate(homePagePath)

          try {
            const avatarResponse = await axios.get('/api/admin/v1/avatar')
            setAvatar(avatarResponse.data)
          } catch (avatarError) {
            // Если ошибка связана с отсутствием аватара, можно просто проигнорировать
            if (avatarError.response && avatarError.response.status === 404) {
              setAvatar(null) // Устанавливаем аватар как null, если его нет
            } else {
              // console.error('Ошибка при получении аватара:', avatarError)
            }
          }
        } catch (userDetailsError) {
          alert(userDetailsError)
          if (userDetailsError.response && userDetailsError.response.status === 403) {
            setError('Доступ запрещен')
          }
        }
      } else {
        setError(message)
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setError('Неверный логин или пароль.')
        return
      }
      setError(
        `Во время обработки Вашего запроса произошла ошибка: ${
          error.response?.data?.message || ' '
        }`
      )
    }
  }

  return (
    <div className={styles.containerForm}>
      <form className={styles.containerLoginForm} onSubmit={handleLogin}>
        <div className={styles.inputContainer}>
          <MailIcon />
          <input
            type="email"
            className={styles.inputField}
            placeholder="Почта"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className={styles.inputContainer}>
          <LockIcon />
          <input
            type={passwordVisible ? 'text' : 'password'}
            className={styles.inputField}
            placeholder="Пароль"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            autoComplete='off'
          />
          <img
            src={passwordVisible ? eyeIcon : closedEyeIcon}
            alt="Toggle Password Visibility"
            className={styles.togglePasswordVisibilityIcon}
            onClick={togglePasswordVisibility}
          />
        </div>

        <button type="submit" className={styles.button}>
          Войти
        </button>
      </form>
      {error && <p>{error}</p>}
      <div className={styles.authContainer} id='VkIdSdkOneTap'></div>
      <p>
        Нет аккаунта?{' '}
        <Link to="/admin/register">Зарегистрироваться здесь </Link>
      </p>
      <p>
        <Link to="/admin/forgot-password">Забыли пароль?</Link>
      </p>
    </div>
  )
}

export default Login