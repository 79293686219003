import React from 'react'
import { NavLink } from 'react-router-dom'
import styles from './CouponsNavbar.module.css'
import { useUser } from '../contexts/UserContext'

const EntertainsNavbar = () => {
  const { user } = useUser()
  return (
    <nav className={styles.navbar}>
      <ul className={styles.navbarList}>
        <li className={styles.navbarItem}>
          <NavLink
            to="/admin/entertaining-content/video-upload"
            className={({ isActive }) =>
              isActive ? styles.activeNavLink : styles.navLink
            }
          >
            Загрузка видео
          </NavLink>
        </li>
        <li className={styles.navbarItem}>
          <NavLink
            to="/admin/entertaining-content/videos"
            className={({ isActive }) =>
              isActive ? styles.activeNavLink : styles.navLink
            }
          >
            Список загруженных видео
          </NavLink>
        </li>
        <li className={styles.navbarItem}>
          <NavLink
            to="/admin/entertaining-content/hashtags"
            className={({ isActive }) =>
              isActive ? styles.activeNavLink : styles.navLink
            }
          >
            Хештеги
          </NavLink>
        </li>
        {user?.role === 'куратор' && (
          <li className={styles.navbarItem}>
          <NavLink
            to="/admin/entertaining-content/categories"
            className={({ isActive }) =>
              isActive ? styles.activeNavLink : styles.navLink
            }
          >
            Категории
          </NavLink>
        </li>
        )}
      </ul>
    </nav>
  )
}

export default EntertainsNavbar
