/*eslint-disable */
import React, { useEffect, useState } from "react";
import axios from "../api/axios";
import penIcon from "../assets/pen_icon.svg";
import deleteIcon from "../assets/delete_icon.svg";
import styles from "./Lesson.module.css";
import VideoLessonUpload from "./VideoLessonUpload";
import ConfirmationModal from "../components/ConfirmModal";
import DeleteLesson from "./DeleteLesson";
import RewardsDropdown from "./RewardsDropdown";

const Lesson = ({ directionUuid, checkLesson, updateCheckLesson, ticketData, setTicketData }) => {
  const [lessonUuid, setLessonUuid] = useState(null);
  const [lessonData, setLessonData] = useState(null);
  const [lessonAuthor, setLessonAuthor] = useState(null);
  const [quizData, setQuizData] = useState([]);
  const [videoData, setVideoData] = useState(null);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [questions, setQuestions] = useState([
    {
      text: "",
      description: "description",
      answers: [
        { is_correct: false, text: "" },
        { is_correct: false, text: "" },
        { is_correct: false, text: "" },
      ],
    },
    {
      text: "",
      description: "description",
      answers: [
        { is_correct: false, text: "" },
        { is_correct: false, text: "" },
        { is_correct: false, text: "" },
      ],
    },
    // ... Add more questions as needed
  ]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const [isLessonFull, setIsLessonFull] = useState(false);

  const fetchLesson = async () => {
    if (!directionUuid) {
      return;
    }
  
    try {
      const response = await axios.get(`/api/admin/v2/learn/lesson?topic_uuid=${directionUuid}`);
      setLessonUuid(response.data.uuid);
      setLessonData(response.data);
      setLessonAuthor(response.data.author);
      setIsLessonFull(response.data.is_full);
      return response.data; // Возвращаем данные для дальнейшей проверки
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setLessonUuid(null);
      } else {
        setResponseMessage(error.message);
        setLessonUuid(null);
      }
    }
  };
  
  const fetchLessonVideos = async () => {
    try {
      const response = await axios.get(`/api/admin/v1/learn/lesson/video?topic_uuid=${directionUuid}`);  
      setVideoData(response.data); // Сохраняем данные видео в состояние
    } catch (error) {
      console.error("Ошибка при получении видео:", error);
    }
  };
  
  const fetchLessonQuiz = async () => {
    try {
      const response = await axios.get(`/api/admin/v1/learn/lesson/quiz?topic_uuid=${directionUuid}`);
      setQuizData(response.data);
      if (response.data && response.data.length > 0) {
        setQuestions(response.data[0].questions);
      } else {
        setQuestions([
          {
            text: "",
            description: "description",
            answers: [
              { is_correct: false, text: "" },
              { is_correct: false, text: "" },
              { is_correct: false, text: "" },
            ],
          },
          {
            text: "",
            description: "description",
            answers: [
              { is_correct: false, text: "" },
              { is_correct: false, text: "" },
              { is_correct: false, text: "" },
            ],
          },
        ]);
      }
    } catch (error) {
      console.error("Ошибка при получении данных теста:", error);
    }
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const lessonResponse = await fetchLesson();
  
        if (lessonResponse) {
          await Promise.all([fetchLessonVideos(), fetchLessonQuiz()]);
        }
      } catch (error) {
      }
    };
  
    fetchInitialData();
  }, []);

  useEffect(() => {
    const updateLessonStatus = () => {
      if (videoData && videoData.length > 0 && quizData && quizData.length > 0) {
        updateCheckLesson(directionUuid, 'full');
        setIsLessonFull(true);
      } else if (videoData?.length > 0 || quizData?.length > 0) {
        updateCheckLesson(directionUuid, 'draft');
        setIsLessonFull(false);
      } else {
        updateCheckLesson(directionUuid, 'none');
        setIsLessonFull(false);
      }
    };
    updateLessonStatus();
  }, [videoData, quizData]);
  
  useEffect(() => {
    if (isFormVisible) {
      const textareas = document.querySelectorAll('textarea');
      textareas.forEach(textarea => {
        textarea.style.height = 'auto';
        textarea.style.height = textarea.scrollHeight + 'px';
      });
    }
  }, [isFormVisible, questions]);

  const handleLessonDeleted = () => {
    setQuestions([
      {
        text: "",
        description: "description",
        answers: [
          { is_correct: false, text: "" },
          { is_correct: false, text: "" },
          { is_correct: false, text: "" },
        ],
      },
      {
        text: "",
        description: "description",
        answers: [
          { is_correct: false, text: "" },
          { is_correct: false, text: "" },
          { is_correct: false, text: "" },
        ],
      },
      // ... Add more questions as needed
    ]);
    fetchLesson();
  };

  // Handler for question text and description change
  const handleQuestionChange = (index, key, value) => {
    const newQuestions = [...questions];
    newQuestions[index][key] = value;
    setQuestions(newQuestions);
  };

  // Handler for answer text change
  const handleAnswerChange = (questionIndex, answerIndex, value) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].answers[answerIndex].text = value;
    setQuestions(newQuestions);
  };

  // Handler for setting the correct answer
  const handleCorrectAnswerChange = (questionIndex, answerIndex) => {
    const newQuestions = [...questions];
    // Reset all answers' is_correct to false
    newQuestions[questionIndex].answers.forEach(
      (answer) => (answer.is_correct = false)
    );
    // Set the selected answer's is_correct to true
    newQuestions[questionIndex].answers[answerIndex].is_correct = true;
    setQuestions(newQuestions);
  };

  const handleCancel = () => {
    fetchLessonQuiz()
    setIsFormVisible(false);
  };

  const validateQuestions = () => {
    let isValid = true;
    const newQuestions = [...questions];

    newQuestions.forEach((question, questionIndex) => {
      if (question.text.length < 5 || question.text.length > 150) {
        question.error = true;
        isValid = false;
      } else {
        question.error = null;
      }

      question.answers.forEach((answer, answerIndex) => {
        if (answer.text.length < 1 || answer.text.length > 100) {
          answer.error = true;
          isValid = false;
        } else {
          answer.error = null;
        }
      });

      const correctAnswers = question.answers.filter(answer => answer.is_correct);
      if (correctAnswers.length !== 1) {
        question.correctAnswerError = true;
        isValid = false;
      } else {
        question.correctAnswerError = null;
      }
    });

    setQuestions(newQuestions);
    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateQuestions()) {
      return;
    }

    const quizPayload = {
      questions: questions.map(({ error, correctAnswerError, ...rest }) => ({
        ...rest,
        answers: rest.answers.map(({ error, ...answerRest }) => answerRest)
      })),
    };

    try {
      let response;
      if (quizData && quizData.length > 0 && quizData[0].uuid) {
        response = await axios.put(
          `/api/admin/v1/learn/lesson/quiz/${quizData[0].uuid}`,
          quizPayload
        );
      } else {
        response = await axios.post(
          `/api/admin/v1/learn/lesson/${lessonUuid}/quiz`,
          quizPayload
        );
      }
      setQuizData([response.data]);
      setQuestions(response.data.questions)
      setResponseMessage("");
      setIsFormVisible(false);
    } catch (error) {
      alert("Ошибка при сохранении теста. " + error.message);
      setResponseMessage(
        error.response?.data?.message || "Ошибка при сохранении теста."
      );
    }
  };

  const createLesson = async () => {
    try {
      const response = await axios.post(
        `/api/admin/v1/learn/lesson?uuid=${directionUuid}`
      );
      setLessonUuid(response.data.uuid);
      setLessonAuthor(response.data.author);
      fetchLesson();
    } catch (error) {
      alert("Ошибка при создании урока. " + error.message);
    }
  };

  const renderQuizContent = () => {
    if (quizData.length === 0) {
      return null;
    }

    return questions.map((question, questionIndex) => (
      <div key={question.uuid} className={styles.questionContainer}>
        <h3>{`${questionIndex + 1}. ${question.text}`}</h3>
        {question.answers.map((answer, answerIndex) => (
          <div
            key={answerIndex}
            className={answer.is_correct ? styles.correctAnswer : ""}
          >
            {`${answerIndex + 1}. ${answer.text}`}
          </div>
        ))}
      </div>
    ));
  };

  const handleDeleteClick = () => {
    setIsModalOpen(true);
  };

  const handleDeleteQuiz = async () => {
    try {
      await axios.delete(
        `/api/admin/v1/learn/lesson/quiz/${quizData[0].uuid}`
      );
      fetchLessonQuiz()
      setResponseMessage("Тест удален.");
      setIsModalOpen(false);
    } catch (error) {
      console.error("Ошибка при удалении теста: ", error);
      setResponseMessage(
        error.response?.data?.message || "Ошибка при удалении теста."
      );
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Function to close the modal without deleting
  };

  return (
    <div className={styles.lessonContainer}>
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onConfirm={handleDeleteQuiz}
      >
        <p>Вы уверены, что хотите удалить тест?</p>
      </ConfirmationModal>
      {!lessonUuid && (
        <div className={styles.lessonHeader}>
          <span onClick={createLesson} className={styles.createLessonButton}>
            [ +добавить урок ]
          </span>
        </div>
      )}
      {lessonUuid && (
        <>
          <span className={isLessonFull ? styles.available : styles.unavailable}>
            {isLessonFull ? "[ доступен ]" : "[ недоступен ]"}
          </span>
        </>
      )}
      {lessonUuid && (
        <div className={styles.rewardGroup}>
          <RewardsDropdown directionUuid={directionUuid} ticketData={ticketData} setTicketData={setTicketData} />
          <DeleteLesson
            lessonUuid={lessonUuid}
            onDeleted={handleLessonDeleted}
            fetchLesson={fetchLesson}
            directionUuid={directionUuid}
            setVideoData={setVideoData}
            setQuizData={setQuizData}
          />
        </div>
      )}
      {lessonUuid && (
        <VideoLessonUpload
          directionUuid={directionUuid}
          lessonUuid={lessonUuid}
          lessonVideo={videoData || []}
          fetchLessonVideos={fetchLessonVideos}
          setIsLessonFull={setIsLessonFull}
        />
)}
      {lessonUuid && !isFormVisible && (
        <div className={styles.quizContainer}>
          <div className={styles.testBtn}>
            <button
              onClick={() => setIsFormVisible(true)}
              className={styles.openQuizButton}
            >
              Тест
              <img src={penIcon} alt="Create Test" className={styles.penIcon} />
            </button>
            {quizData.length > 0 && (
              <button
                type="button"
                onClick={handleDeleteClick}
                className={styles.deleteQuizBtn}
              >
                <img
                  src={deleteIcon}
                  alt="Delete Test"
                  className={styles.deleteBtn}
                />
              </button>
            )}
          </div>
          {renderQuizContent()}
          {responseMessage && (
            <p className={styles.responseMessage}>{responseMessage}</p>
          )}
        </div>
      )}
      {isFormVisible && (
        <form onSubmit={handleSubmit} className={styles.quizForm}>
          Тест
          {questions.map((q, questionIndex) => (
            <div key={questionIndex} className={styles.questionBlock}>
              <textarea
                maxLength={150}
                placeholder="Вопрос"
                value={q.text}
                onChange={(e) =>
                  handleQuestionChange(questionIndex, "text", e.target.value)
                }
                className={`${styles.questionInput} ${
                  q.error ? styles.invalidInput : ""
                }`}
                rows={1}
                onInput={(e) => {
                  e.target.style.height = 'auto';
                  e.target.style.height = e.target.scrollHeight + 'px';
                }}
              />
              {q.answers.map((a, answerIndex) => (
                <label key={answerIndex} className={styles.answerLabel}>
                  <input
                    type="radio"
                    name={`question-${questionIndex}-correct-answer`}
                    checked={a.is_correct}
                    onChange={() =>
                      handleCorrectAnswerChange(questionIndex, answerIndex)
                    }
                    className={styles.radioInput}
                  />
                  <textarea
                    maxLength={100}
                    placeholder={`Ответ ${answerIndex + 1}`}
                    value={a.text}
                    onChange={(e) =>
                      handleAnswerChange(
                        questionIndex,
                        answerIndex,
                        e.target.value
                      )
                    }
                    className={`${styles.answerInput} ${
                      a.error ? styles.invalidInput : ""
                    }`}
                    rows={1}
                    onInput={(e) => {
                      e.target.style.height = 'auto';
                      e.target.style.height = e.target.scrollHeight + 'px';
                    }}
                  />
                </label>
              ))}
            </div>
          ))}
          <div className={styles.errorMessageContainer}>
            {questions.some(q => q.error || q.correctAnswerError || q.answers.some(a => a.error)) && (
              <>
                {questions.some(q => q.error) && (
                  <p className={styles.formErrorMessage}>
                    Текст вопроса должен содержать от 5 до 150 символов.
                  </p>
                )}
                {questions.some(q => q.answers.some(a => a.error)) && (
                  <p className={styles.formErrorMessage}>
                    Текст варианта ответа должен содержать от 1 до 100 символов.
                  </p>
                )}
                {questions.some(q => q.correctAnswerError) && (
                  <p className={styles.formErrorMessage}>
                    Необходимо отметить один правильный ответ в каждом вопросе.
                  </p>
                )}
              </>
            )}
          </div>
          <div className={styles.buttonGroup}>
            <button type="submit" className={styles.saveButton}>
              Сохранить
            </button>
            <button
              type="button"
              onClick={handleCancel}
              className={styles.cancelButton}
            >
              Отмена
            </button>
          </div>
        </form>
      )}

      {lessonUuid && lessonData && (
        <div className={styles.lessonInfoText}>
          {lessonData.author && lessonData.author.first_name && lessonData.author.last_name && (
            <p>Автор: {lessonAuthor.first_name + ' ' + lessonAuthor.last_name}</p>
          )}
          <p>
            Дата создания:{" "}
            {new Date(lessonData.created_at).toLocaleDateString("en-GB")}
          </p>
        </div>
      )}
    </div>
  );
};

export default Lesson;
