import React, { useState, useEffect } from 'react'
import axios from '../api/axios'
import styles from './HashtagSelector.module.css'

const HashtagSelector = ({ onSelectHashtag }) => {
  const [hashtags, setHashtags] = useState([])
  const [searchQuery, setSearchQuery] = useState('')

  useEffect(() => {
    fetchHashtags()
  }, [searchQuery])

  const fetchHashtags = async () => {
    // if (searchQuery === '') {
    //   setHashtags([])
    //   return
    // }
    try {
      const apiUrl = `/api/admin/v1/tags?offset=0&limit=30&query=${encodeURIComponent(searchQuery)}`
      const response = await axios.get(apiUrl)
      setHashtags(response.data.tags)
    } catch (error) {
      console.error('Error fetching hashtags: ', error)
    }
  }

  return (
        <div className={styles.hashtagSelectorContainer}>
          <span># </span>
            <input className={styles.hashtagSelectorContainerInput}
                type="text"
                placeholder="Поиск..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
            />
            <ul className={styles.hashtagList}>
                {hashtags.map((hashtag) => (
                    <li key={hashtag.uuid} className={styles.hashtagItem} onClick={() => onSelectHashtag(hashtag)}>
                        #{hashtag.name}
                    </li>
                ))}
            </ul>
        </div>
  )
}

export default HashtagSelector
