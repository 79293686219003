import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from 'react'
import axios from '../api/axios'
import ConfirmationModal from './ConfirmModal'
import ImageCropper from './ImageCropper/ImageCropper'
import styles from './ImgUploader.module.css'
import plusIcon from '../assets/plus_gray.svg'
import deleteIcon from '../assets/logo_delete.svg'

const ImgUploader = forwardRef(
  ({
    onFileSelect,
    entityUuid,
    imageUrl,
    imageUuid,
    setImageUuid,
    onImageUpdate,
    fetchEntities,
    uploadApiUrl,
    updateApiUrl,
    deleteApiUrl,
    defaultImage
  }, ref) => {
    const [preview, setPreview] = useState(imageUrl || defaultImage)
    const [selectedFile, setSelectedFile] = useState(null)
    const [originalFile, setOriginalFile] = useState(null) // Состояние для хранения оригинального файла
    const [cropping, setCropping] = useState(false)
    const [loading, setLoading] = useState(false)
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
    const fileInputRef = useRef(null)
    const [notification, setNotification] = useState('')

    useEffect(() => {
      setPreview(imageUrl || defaultImage)
    }, [imageUrl, defaultImage])

    const handleFileChange = (event) => {
      setNotification('')
      const file = event.target.files[0]
      if (!file) return

      const validImageTypes = ['image/png', 'image/jpeg']
      if (!validImageTypes.includes(file.type)) {
        alert('Только файлы PNG и JPEG поддерживаются')
        return
      }

      const maxSizeInMB = 5
      if (file.size > maxSizeInMB * 1024 * 1024) {
        alert(`Файл превышает максимальный размер в ${maxSizeInMB}MB`)
        return
      }

      const reader = new FileReader()
      reader.onload = () => {
        setPreview(reader.result)
        setCropping(true)
      }
      reader.readAsDataURL(file)
      setOriginalFile(file) // Сохраняем оригинальный файл
    }

    const resetSelectedFile = () => {
      setPreview(imageUrl || defaultImage)
      setSelectedFile(null)
      onFileSelect(null)
      if (fileInputRef.current) {
        fileInputRef.current.value = ''
      }
    }

    const confirmDeleteImage = () => {
      setIsConfirmModalOpen(true)
    }

    const handleDeleteImage = async () => {
      setNotification('')
      setLoading(true)
      setIsConfirmModalOpen(false)
      try {
        await axios.delete(`${deleteApiUrl}${entityUuid}`)

        setPreview(defaultImage)
        setImageUuid(null)
        onFileSelect(null)
        onImageUpdate(null, null)
        setNotification('Изображение удалено')
        fetchEntities()
      } catch (error) {
        alert('Ошибка удаления изображения.', error)
      } finally {
        setLoading(false)
      }
    }

    const handleCropComplete = (croppedFile) => {
      setCropping(false)
      setPreview(URL.createObjectURL(croppedFile))
      setSelectedFile(croppedFile)
      onFileSelect(croppedFile)
    }

    const handleCancelCrop = () => {
      setCropping(false)
      // setPreview(URL.createObjectURL(originalFile)
      setSelectedFile(originalFile) // Устанавливаем оригинальный файл
      onFileSelect(originalFile) // Передаем оригинальный файл обратно
    }

    useImperativeHandle(ref, () => ({
      uploadImage: async (uuid) => {
        if (!selectedFile) return

        const formData = new FormData()
        formData.append('file', selectedFile)
        setNotification('')
        setLoading(true)
        try {
          const requestType = imageUuid ? 'put' : 'post'
          const url = imageUuid ? `${updateApiUrl}${uuid}` : `${uploadApiUrl}${uuid}`

          const response = await axios[requestType](url, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
          })

          if (response.data) {
            const newImageUuid = response.data.image_uuid || response.data.logo_uuid
            const newImageUrl = response.data.url
            setImageUuid(newImageUuid)
            setPreview(newImageUrl)
            onImageUpdate(newImageUrl, newImageUuid)
          }
        } catch (error) {
          alert('Ошибка загрузки изображения. ' + error.response?.data?.message)
          // console.log(error.response?.data?.message)
        } finally {
          setLoading(false)
        }
      }
    }))

    if (cropping) {
      return (
        <ImageCropper
          image={preview}
          onCancel={handleCancelCrop}
          onCropComplete={handleCropComplete}
        />
      )
    }

    return (
      <>
        <div className={styles.logoWrapper}>
          <img src={preview} alt='Preview' className={styles.logoImg} />

          <div
            className={styles.addIconWrapper}
            onClick={() => fileInputRef.current.click()}
          >
            <img src={plusIcon} alt='Добавить изображение' className={styles.addIcon} />
          </div>

          {!loading && (
            selectedFile
              ? (
                <div className={styles.deleteIconWrapper} onClick={resetSelectedFile}>
                  <img src={deleteIcon} alt='Отменить выбор файла' className={styles.deleteIcon} />
                </div>)
              : imageUuid && (
                <div className={styles.deleteIconWrapper} onClick={confirmDeleteImage}>
                  <img src={deleteIcon} alt='Удалить изображение' className={styles.deleteIcon} />
                </div>
              )
          )}

          <input
            type='file'
            accept='image/png, image/jpeg'
            onChange={handleFileChange}
            ref={fileInputRef}
            style={{ display: 'none' }}
          />

          <ConfirmationModal
            isOpen={isConfirmModalOpen}
            onClose={() => setIsConfirmModalOpen(false)}
            onConfirm={handleDeleteImage}
          >
            Вы уверены, что хотите удалить изображение?
          </ConfirmationModal>
        </div>
        {notification && <div className={styles.notificationMessage}>{notification}</div>}
      </>
    )
  }
)

ImgUploader.displayName = 'ImgUploader'

export default ImgUploader
