// import React, { useEffect, useState } from 'react'
import React, { useState } from 'react'
import axios from '../api/axios'
import styles from './VideoEdit.module.css'
import HashtagSelector from './HashtagSelector'
import HashtagItemsWithRemove from './hashtag/HashtagItemsWithRemove'
import CategorySelector from './categories/CategorySelector'
import { useUser } from '../contexts/UserContext'

const VideoEdit = ({ videoId, videoUuid, currentTitle, currentDescription, fetchVideos, fetchHashtags, currentHashtags, setCurrentHashtags, updateParentVideoHashtags, currentCategories, setCurrentCategories, updateParentVideoCategories, onClose }) => {
  const { user } = useUser()
  const [title, setTitle] = useState(currentTitle)
  const [description, setDescription] = useState(currentDescription)
  const [showHashtagSelector, setShowHashtagSelector] = useState(false)
  const [showCategorySelector, setShowCategorySelector] = useState(false)

  const toggleHashtagSelector = () => {
    setShowHashtagSelector(!showHashtagSelector)
  }
  const toggleCategorySelector = () => {
    setShowCategorySelector(!showCategorySelector)
  }

  const handleSelectCategory = async (selectedCategory) => {
    try {
      const response = await axios.put(`/api/admin/v1/cats/${selectedCategory.uuid}/video/${videoUuid}`)
      if (response.status === 200) {
        setCurrentCategories(response.data.cats)
        updateParentVideoCategories(videoUuid, response.data.cats)
      }
    } catch (error) {
      alert('Ошибка при добавлении хэштега. ' + error.response?.data?.message || error)
      // console.log('Ошибка при удалении хэштега. ', error.response?.data?.message || error)
    }
  }
  const handleCategoryDelete = async (categoryUuid) => {
    try {
      const response = await axios.delete(`/api/admin/v1/cats/${categoryUuid}/video/${videoUuid}`)
      if (response.status === 200) {
        setCurrentCategories(response.data.cats)
        updateParentVideoCategories(videoUuid, response.data.cats)
      }
    } catch (error) {
      // console.error('Ошибка при удалении хэштега. ' + error.response?.data?.message || error)
      alert('Ошибка при удалении категории. ' + error.response?.data?.message || error)
    }
  }

  const handleSelectHashtag = async (selectedHashtag) => {
    try {
      const response = await axios.put(`/api/admin/v1/tag/video/${videoUuid}/${selectedHashtag.uuid}`)
      if (response.status === 200) {
        setCurrentHashtags(response.data.tags)
        updateParentVideoHashtags(videoUuid, response.data.tags)
      }
    } catch (error) {
      alert('Ошибка при добавлении хэштега. ' + error)
    }
  }

  const handleHashtagDelete = async (hashtagUuid) => {
    try {
      const response = await axios.delete(`/api/admin/v1/tag/video/${videoUuid}/${hashtagUuid}`)
      if (response.status === 200) {
        setCurrentHashtags(response.data.tags)
        updateParentVideoHashtags(videoUuid, response.data.tags)
      }
    } catch (error) {
      // alert('Error deleting hashtag: ', error.response || error)
      // console.error('Ошибка при удалении хэштега. ', error.response || error)
      alert('Ошибка при удалении хэштега. ' + error.response || error)
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      const formData = new FormData()
      formData.append('id', videoId)
      formData.append('title', title)
      formData.append('description', description)

      const response = await axios.put(`api/admin/v1/content/facts/${videoId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })

      if (response.status === 200) {
        onClose()
        fetchVideos()
      }
    } catch (error) {
      alert('Ошибка при обновлении данных видео. ' + error.response || error)
    }
  }

  return (
        <div className={styles.videoEditContainer}>
            <form className={styles.videoEditContainerForm} onSubmit={handleSubmit}>
                <label htmlFor="title">Заголовок:* </label>
                <input
                    id="title"
                    pattern="^(?! ).*$"
                    maxLength={60}
                    required
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />

                <label htmlFor="description">Описание: </label>
                <textarea
                    id="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
                <button type="submit"className={styles.saveBtn}>Сохранить</button>
            </form>

            {user?.role === 'куратор'
              ? (
                  <div className={styles.hashtagsContainer}>
                        <HashtagItemsWithRemove
                          hashtags={currentCategories.map(category => ({
                            ...category,
                            uuid: category.uuid,
                            name: category.name
                          }))}
                          onRemoveHashtag={handleCategoryDelete}
                          hideHash={true}
                        />

                      <div className={styles.addHashtagContainer}>
                          <span
                              onClick={toggleCategorySelector}
                              className={styles.addHashtag}
                          >Добавить категорию+</span>
                          {showCategorySelector && <CategorySelector onSelectCategory={handleSelectCategory} />}
                      </div>
                  </div>
                )
              : ''
            }

            <div className={styles.hashtagsContainer}>

                  <HashtagItemsWithRemove
                    hashtags={currentHashtags.map(hashtag => ({
                      ...hashtag,
                      uuid: hashtag.tag_uuid,
                      name: hashtag.tag_name
                    }))}
                    onRemoveHashtag={handleHashtagDelete}
                  />

                <div className={styles.addHashtagContainer}>
                    <span
                        onClick={toggleHashtagSelector}
                        className={styles.addHashtag}
                    >Добавить хештег+</span>
                    {showHashtagSelector && <HashtagSelector onSelectHashtag={handleSelectHashtag} />}
                </div>
            </div>

        </div>
  )
}

export default VideoEdit
