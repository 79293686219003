import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import EntertainsNavbar from '../components/EntertainsNavbar'
import VideoUpload from './VideoUpload'
import VideoList from './VideoList'
import HashtagList from './HashtagList'
import CategoriesList from '../components/categories/CategoriesList'
import ProtectedRoute from '../routes/ProtectedRoute'

const EntertainingContent = () => {
  return (
    <div className='container'>
      <EntertainsNavbar />
      <Routes>
        <Route path="video-upload" element={<VideoUpload />} />
        <Route path="videos" element={<VideoList />} />
        <Route path="hashtags" element={<HashtagList />} />
        <Route path="categories" element={ <ProtectedRoute allowedRoles={['куратор']} > <CategoriesList /> </ProtectedRoute> } />
        <Route path="/" element={<Navigate to = "/admin/entertaining-content/video-upload" replace />} />

      </Routes>
    </div>
  )
}

export default EntertainingContent
