import React, { useState, useEffect } from 'react'
import axios from '../../api/axios'
import styles from '../HashtagSelector.module.css'

const CategorySelector = ({ onSelectCategory }) => {
  const [categories, setCategories] = useState([])
  const [searchQuery, setSearchQuery] = useState('')

  useEffect(() => {
    fetchCategories()
  }, [searchQuery])

  const fetchCategories = async () => {
    // if (searchQuery === '') {
    //   setCategories([])
    //   return
    // }
    try {
      const apiUrl = `/api/admin/v1/cats?offset=0&limit=30&query=${encodeURIComponent(searchQuery)}`
      const response = await axios.get(apiUrl)
      setCategories(response.data.category)
    } catch (error) {
      console.error('Error fetching categories: ', error)
    }
  }

  return (
        <div className={styles.hashtagSelectorContainer}>
            <input className={styles.hashtagSelectorContainerInput}
                type="text"
                placeholder="Поиск..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
            />
            <ul className={styles.hashtagList}>
                {categories.map((category) => (
                    <li key={category.uuid} className={styles.hashtagItem} onClick={() => onSelectCategory(category)}>
                        {category.name}
                    </li>
                ))}
            </ul>
        </div>
  )
}

export default CategorySelector
